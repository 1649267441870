import "../../styles/globals.scss";
import "../../styles/croppie.scss";

// polyfills
import "intersection-observer";

import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { pageview } from "../libs/gtag";
import type { AppProps } from "next/app";
import { Provider } from "next-auth/client";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../../next-i18next.config.js";

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <Provider session={session}>
      <Component {...pageProps} />
    </Provider>
  );
};

export default appWithTranslation(MyApp, nextI18NextConfig);
