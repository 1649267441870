const path = require("path");
const { format } = require("date-fns");
const { en, tr } = require("date-fns/locale");

const locales = { en, tr };

module.exports = {
  i18n: {
    locales: ["tr", "en"],
    defaultLocale: "tr",
    fallbackLng: "en",
    ns: ["common"],
  },
  localePath: path.resolve("./public/locales"),
  react: {
    useSuspense: false,
    wait: true,
  },
  interpolation: {
    format: function (value, dateFormat, lng) {
      const date = new Date(value);
      const locale = locales[lng];
      return format(date, dateFormat, { locale });
    },
    escapeValue: false,
  },
  serializeConfig: false,
};
