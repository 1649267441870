export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID as string;
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID as string;

declare global {
  interface Window {
    gtag: any;
  }
}

interface EventProps {
  action: string;
  category: string;
  label?: string;
  value?: string | number;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: EventProps) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
